import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { Locale } from 'types/common';
import { GetServerSideProps } from 'next';
import { getQueryString } from '../utils';
import { STRAPI_URL } from '../config';

interface IProps {
  locales: { language_code: Locale }[];
}

function LandingPage({ locales }: IProps) {
  const router = useRouter();
  const [initialRender, setInitialRender] = useState(true);
  const goToPage = async () => {
    const { language } = window.navigator;
    // if the languages are not available we will use a few known default languages
    let availableLanguages = [
      {
        language_code: 'fr',
      },
      {
        language_code: 'de',
      },
      {
        language_code: 'es',
      },
      {
        language_code: 'it',
      },
      {
        language_code: 'ru',
      },
      {
        language_code: 'pt',
      },
    ];
    if (locales?.length) {
      availableLanguages = locales;
    }
    const currentLocale = availableLanguages.filter((locale) =>
      language.includes(locale.language_code),
    );
    if (currentLocale.length) {
      const { language_code } = currentLocale[0];
      localStorage?.setItem('locale', language_code);
      window.location.href = `/${language_code}`;
    } else {
      localStorage?.setItem('locale', 'en');
      window.location.href = '/en';
    }
  };
  useEffect(() => {
    if (!initialRender) {
      goToPage();
    }
  }, [router, initialRender]);

  useEffect(() => {
    setTimeout(() => {
      setInitialRender(false);
    }, 1000);
  }, []);
  return (
    <div className="bg-white dark:bg-gray-900 w-full min-h-screen">
      <NextSeo
        title="QuodArca | Home"
        description="Data management built around your needs."
        openGraph={{
          type: 'website',
          url: 'https://website.quodarca.net',
          title: 'QuodArca | Home',
          description: 'Data management built around your needs.',
          images: [
            {
              url: 'https://website.quodarca.net/img/hero-image.png',
              width: 800,
              height: 600,
              alt: 'QuodArca | Data management built around your needs.',
            },
            {
              url: 'https://website.quodarca.net/img/pricing_hero.png',
              width: 800,
              height: 600,
              alt: 'QuodArca | Data management built around your needs.',
            },
          ],
        }}
      />
    </div>
  );
}
export default LandingPage;

export const getServerSideProps: GetServerSideProps = async () => {
  const localesQuery = getQueryString(['language'], 'en');
  let locales: { locales: { language_code: Locale } }[] = [];
  await axios
    .get(
      `${STRAPI_URL}/available-language?${localesQuery}&publicationState=preview`,
    )
    .then((res) => {
      locales = res.data.data.attributes.language;
    })
    .catch((err) => err);
  // context.res.setHeader(
  //   'Cache-Control',
  //   'public, s-maxage=30, stale-while-revalidate=59',
  // );
  return {
    props: {
      locales,
    },
  };
};
